<!-- <div class="card mb-2">
  <div class="card__inner d-flex mb-2">
    <img [src]="blog?.imageCover" alt="product-indv" class="mr-4 d-md-block d-none card--cover" />
    <div class="product-details-container">
      <div class="d-flex justify-content-between">
        <p class="w-75 title">{{blog?.title}}</p>
        <div class="add-to-cart">
          <p class="product-click p-1">{{blog?.readMinutes}} min</p>
        </div>
      </div>
      <p class="w-75" align="justify">
        {{blog?.summary.length > 75 ? blog?.summary.substr(0, 70) + '....' : blog?.summary}}</p>
      <div class="border"></div>
      <div class="d-flex mt-3 justify-content-between flex-column">
        <div class="p-1 product-click d-flex justify-content-center align-items-center title">
          राजभाषा </div>
        <div class="d-flex justify-content-between">
          <div class="p-1 product-click d-flex justify-content-center align-items-center mt-2">
            {{blog?.ratingsAverage.toFixed(2)}} ({{ blog?.ratingsQuantity }})</div>
          <div class="p-1 product-click d-flex justify-content-center align-items-center mt-2">
            {{blog?.createdAt | date}}</div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="card-post indv-post mt-2 ">
  <div class="color-strip"></div>
  <div class="card--content">

    <h6 class="step ">{{ blog?.title}}<br>
    </h6>

    <div class="d-flex justify-content-between">
      <h4 class="title light mb-half blue "> {{ blog?.createdAt | date }}</h4>
      <h4 class="title text-dark "> {{ blog?.genre}}</h4>
    </div>

    <div class="summary mb-half ">
      <p class="degree-concepts mb-0"><span
          class="skills">{{blog?.summary.length < 200 ?  blog?.summary : blog?.summary.substring(0, 200) + '...'}}</span>
      </p>
    </div>
    <div class="mb-1 mt-4 d-flex justify-content-between"><mark class="mb-half capitalize">
        Average Rating - {{ blog?.ratingsAverage.toFixed(1)}}
      </mark>
      <div class="product-click p-1 ">
        <div>{{ blog?.readMinutes }} min</div>
      </div>
    </div>
  </div>
</div>
