<div class="container-lg container-fluid">
  <div class="w-100 d-flex justify-content-center mt-5">
    <a href="https://agile-dusk-83055.herokuapp.com/api/v1/users/google">
      <button class="btn google text-center w-md-25"> Sign up with Google <i class="fab fa-google"></i></button>
    </a>
  </div>
  <div class="w-100 d-flex justify-content-center mt-3">
    <mark>Or</mark>
  </div>
  <div class="content">
    <div class="left">
      <div class="create-account text-center">
        Create your Account
      </div>
      <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
        <div class="name">
          <input placeholder="First Name" formControlName="name" class="form-control mb-3" id="name" />
          <input placeholder="Last Name" formControlName="lastName" class="form-control mb-3" id="lastName" />
        </div>
        <div class="username">
          <input placeholder="Email" formControlName="email" class="form-control mb-3" id="email" />
        </div>

        <div class="password">
          <input placeholder="password" formControlName="password" type="password" id="password" class="form-control" />
          <input placeholder="confirm" formControlName="passwordConfirm" type="password" id="passwordConfirm"
            class="form-control" />
        </div>
        <div class="password-line">
          Use 5 or more characters
        </div>
        <div class="content-footer">
          <div routerLink="/login" class="sign-in outline-none pointer">
            Sign in instead
          </div>
          <button class="next google">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
  <p *ngIf="verified && !verified.confirmed" class="sign-in text-center mt-5 text-success lead">Please check your email
    (spam) - {{ verified?.email }} to verify your email</p>
  <p class="text-danger text-center">{{error}}</p>
</div>
