import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { BlogsService } from '../services/blogs.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signupForm = new FormGroup({
    email: new FormControl('', [
      Validators.required
    ]),
    password: new FormControl('', [
      Validators.required
    ]),
    passwordConfirm: new FormControl('', [
      Validators.required
    ]),
    name: new FormControl('', [
      Validators.required
    ]),
    lastName: new FormControl('')
  })

  public error;
  public verified;

  constructor(private blogService: BlogsService, private route: Router) { }

  ngOnInit(): void {

  }

  onSubmit() {
    if (this.signupForm.valid) {
      return this.blogService.authSignup(this.signupForm.value).subscribe({
        next: res => {
          this.verified = res.data.user
          this.signupForm.reset();
          this.error = ''
          setTimeout(() => {
            this.route.navigateByUrl('/login')
          }, 3000);
        },
        error: err => {
          if (err.error.data.name === 'MongoError') {
            this.error = 'Email already exists!'
          } else {
            this.error = err.error.data.message
          }
        }
      })
    }
  }
}
