<div class="container-lg container-fluid mt-5">
  <h1 class="mb-5">Dr. Vanama Venkateswara Rao</h1>
  <div class="row">
    <div class="col-12 col-md-6 first mb-3 d-flex align-items-center">
      Get noticed
      Our Career Coaches are ready to help you get the attention of recruiters and hiring managers. Speak 1:1 with a
      Career
      Coach or submit your resume, LinkedIn, or GitHub profiles for review.
    </div>
    <div class="col-12 col-md-6">
      <img
        src="https://images.unsplash.com/photo-1561677843-39dee7a319ca?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        alt="image" srcset="">
    </div>
  </div>
  <hr />
  <div class="row mt-5">
    <div class="col-12 col-md-6">
      <img
        src="https://images.unsplash.com/photo-1542156822-6924d1a71ace?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        alt="image">
    </div>
    <div class="col-12 col-md-6 first mt-3 d-flex align-items-center">
      Get noticed
      Our Career Coaches are ready to help you get the attention of recruiters and hiring managers. Speak 1:1 with a
      Career
      Coach or submit your resume, LinkedIn, or GitHub profiles for review.
    </div>
  </div>
    <hr />
  <div class="row mt-5">
    <div class="col-12 col-md-6 first mb-3 d-flex align-items-center">
      Get noticed
      Our Career Coaches are ready to help you get the attention of recruiters and hiring managers. Speak 1:1 with a
      Career
      Coach or submit your resume, LinkedIn, or GitHub profiles for review.
    </div>
    <div class="col-12 col-md-6">
      <img
        src="https://images.unsplash.com/photo-1561677843-39dee7a319ca?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"
        alt="image" srcset="">
    </div>
  </div>
</div>
