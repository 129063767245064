import { Component, OnInit, Input } from '@angular/core';
import { BlogsService } from '../../services/blogs.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public me;
  public onDropdownShow;
  public dummayArray = new Array(2);
  constructor(private router: Router, private blogService: BlogsService) { }

  ngOnInit(): void {
    this.onUser();
    this.onCurrentUser();
  }

  onUser() {
    return this.blogService.auth().subscribe({
      next: (res) => {
        this.me = res.data
      }
    })
  }

  onCurrentUser() {
    return this.blogService.getGoogleUser().subscribe({
      next: (res) => {
        if (res && res.data) {
          this.me = res.data.user
          localStorage.setItem('jwt', res.token);
        }
      },
    })
  }

  onDropdown() {
    // this.close = false;
    // this.onDropdownShow = this.close;
    // this.close = false;
  }

  logout() {
    return this.blogService.logout().subscribe({
      next: (res) => {
        window.localStorage.removeItem('jwt');
        window.location.href = "/"
        // this.router.navigateByUrl('/')
      }
    })
  }
}
