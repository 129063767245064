<h1 class="display-4 text-center title mt-5">
  मेरा ब्लॉग पोस्ट
</h1>
<div *ngIf="allBlogs" class="container-lg container-fluid">
  <div class="form-group mt-4 d-flex justify-content-center">
    <label class="d-none" for="search">Search</label>
    <input (change)="onSearch($event)" type="text" class="form-control w-75 search__input"
      placeholder="type and press enter" id="search">
  </div>
  <div class="row">
    <div class="col-lg-4">
      <form [formGroup]="filterForm">
        <p class="display-4 title text-center">Filter By</p>
        <div class="w-100">
          <!-- <p>Ratings</p> -->
          <label for="select">Ratings</label>
          <select id="select" formControlName="ratingsAverage" class="custom-select custom-select-sm mb-4">
            <option selected value="-ratingsAverage">High</option>
            <option value="ratingsAverage">Low</option>
          </select>
        </div>
        <div (click)="onFilterSubmit()" class="product-click common-btn text-center p-2 mb-4">Filter</div>
      </form>
      <span (click)="onFilterCategory({'genre': topic})" *ngFor="let topic of topics"
        class="badge badge-pill badge-light ml-2">{{ topic }}</span>
      <!-- </div> -->
      <div id="container" class="d-flex justify-content-center flex-column align-items-center">
        <!-- <div id="success-box"> -->
        <div routerLink="/about" class="face pointer outline-none">

          <div class="eye"></div>
          <div class="eye right"></div>
          <div class="mouth happy"></div>
        </div>
        <div class="shadow scale mt-5"></div>
      </div>
    </div>
    <div class="col-lg-8">
      <div [routerLink]="['blog',blog?.id]" *ngFor="let blog of allBlogs" class="indv-post">
        <app-card [blog]="blog"></app-card>
      </div>
    </div>
  </div>
  <!-- <textarea name="textarea"></textarea> -->
  <div class="mt-2 mb-2 d-flex justify-content-end">
    <div [class.d-none]="pageNumber === 1" class="product-click p-2 common-btn" (click)="onPaginatePrev()">Previous
    </div>
    <div [class.d-none]="allBlogs.length < 6" class="product-click ml-5 p-2 common-btn" (click)="onPaginateNext()">Next
    </div>
  </div>
</div>
<div *ngIf="!allBlogs" class="d-flex justify-content-center align-items-center review--open">
  <div class="spinner-grow text-dark ml-2" role="status" *ngFor="let data of dummayArray">
    <span class="sr-only">Loading...</span>
  </div>
</div>

<div *ngIf="!allBlogs" class="d-flex justify-content-center mt-5 display-4">No blog posts!</div>
