import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SignupModule } from './signup/signup.module';
import { AuthGuard } from './auth.guard';
import { AboutComponent } from './about/about.component';


const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule)
  },
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'first-time/:id',
    component: HomeComponent
  },
  {
    path: 'blog',
    loadChildren: () => import('./post/post.module')
      .then((m) => m.PostModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then((m) => m.LoginModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then((m) => m.ChangePasswordModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then((m) => m.SignupModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule)
  },
  {
    path: 'admin-profile',
    canLoad: [AuthGuard],
    loadChildren: () => import('./admin-profile/admin-profile.module').then((m) => m.AdminProfileModule)
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./verify-email/verify-email.module').then((m) => m.VerifyEmailModule)
  },
  {
    path: 'about',
    component: AboutComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
