import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GBHttpInterceptor } from './services/http-interceptor';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SignupComponent } from './signup/signup.component';
import { QuillModule } from 'ngx-quill'
import { ReactiveFormsModule } from '@angular/forms';
import { AboutComponent } from './about/about.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    SignupComponent,
    AboutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    QuillModule.forRoot(),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: GBHttpInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
