import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs';

@Injectable()
export class GBHttpInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const jwt = localStorage.getItem('jwt');
    let httpHeaders = new HttpHeaders()
      .set('Authorization', `Bearer ${jwt}`)
    const modifiedReq = req.clone({
      withCredentials: true,
      headers: httpHeaders
    });
    return next.handle(modifiedReq)
  }
}
