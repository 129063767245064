import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BlogsService {
  constructor(private http: HttpClient) { }

  getAllBlogs(pn, filter, limit = 6) {
    filter.genre = filter.genre && filter.genre !== 'All' ? `&genre=${filter.genre}` : ''
    return this.http.get<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/posts?page=${pn}&limit=${limit}&sort=${filter.ratingsAverage},${filter.createdAt}${filter.genre}`)
  }

  getEntireBlogPosts() {
    return this.http.get<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/posts`)
  }

  getGenres() {
    return this.http.get<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/posts/genre`)
  }

  getPost(id) {
    return this.http.get<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/posts/${id}`);
  }

  getReviews(id, pn) {
    return this.http.get<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/posts/${id}/reviews?limit=6&page=${pn}`)
  }

  auth() {
    return this.http.get<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/users/me`)
  }

  authLogin(details) {
    return this.http.post<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/users/login`, {
      email: details.email,
      password: details.password
    })
  }

  authSignup(details) {
    return this.http.post<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/users/signup`, {
      ...details
    })
  }

  postReview(details, postId) {
    return this.http.post<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/posts/${postId}/reviews`, {
      review: details.review,
      rating: details.rating
    })
  }

  postBookmark(postId) {
    return this.http.post<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/users/${postId}/bookmarks`, '')
  }

  updateReview({ review, rating }, reviewId) {
    return this.http.patch<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/reviews/${reviewId}`, {
      review, rating
    })
  }

  deleteReview(reviewId) {
    return this.http.delete<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/reviews/${reviewId}`)
  }

  deleteBookmark(bookmarkId) {
    return this.http.delete<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/bookmarks/${bookmarkId}`)
  }

  logout() {
    return this.http.get<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/users/logout`)
  }

  sendNewToken({ email }) {
    return this.http.post(`https://agile-dusk-83055.herokuapp.com/api/v1/users/forgot-password`, {
      email
    })
  }

  changePassword({ password, passwordConfirm }, token) {
    return this.http.patch(`https://agile-dusk-83055.herokuapp.com/api/v1/users/reset-password/${token}`, {
      password, passwordConfirm
    })
  }

  deleteAccount() {
    return this.http.delete<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/users/delete-me`)
  }

  updatePassword({ passwordCurrent, password, passwordConfirm }) {
    return this.http.patch(`https://agile-dusk-83055.herokuapp.com/api/v1/users/update-password`, {
      passwordCurrent, password, passwordConfirm
    })
  }

  updateMe(details) {
    return this.http.patch<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/users/update-me`, {
      ...details
    })
  }

  recentBlogs() {
    return this.http.get(`https://agile-dusk-83055.herokuapp.com/api/v1/posts/top-5-posts`)
  }

  removePost(postId) {
    return this.http.delete(`https://agile-dusk-83055.herokuapp.com/api/v1/posts/${postId}`)
  }

  updatePost(postId, { body, title, summary, genre }) {
    return this.http.patch(`https://agile-dusk-83055.herokuapp.com/api/v1/posts/${postId}`, {
      body,
      title,
      summary,
      genre
    })
  }

  createPost({ body, title, summary, genre }) {
    return this.http.post(`https://agile-dusk-83055.herokuapp.com/api/v1/posts`, {
      body,
      title,
      summary,
      genre
    })
  }

  totalUsers() {
    return this.http.get<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/users`)
  }

  searchBlog(searchTerm) {
    return this.http.get<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/posts/search?term=${searchTerm}`)
  }

  getGoogleUser() {
    return this.http.get<any>(`https://agile-dusk-83055.herokuapp.com/api/v1/users/current_user`)
  }
}
