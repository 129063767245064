import { Component, OnInit } from '@angular/core';
import { BlogsService } from '../services/blogs.service';
import { FormGroup, FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public allBlogs: any;
  finalData;
  currentPage = 0;
  pageNumber = 1;
  public dummayArray = new Array(10);
  filter = { createdAt: "-createdAt" }
  public topics = []
  public term;

  filterForm = new FormGroup({
    ratingsAverage: new FormControl('-ratingsAverage', [
      Validators.required
    ]),
    createdAt: new FormControl('createdAt', [
      Validators.required
    ])
  })

  constructor(private blogService: BlogsService, private router: Router) { }

  ngOnInit(): void {
    this.onAllBlogs(this.pageNumber, this.filter);
    // this.onSearch(this.term)
    this.blogService.getGenres().subscribe({
      next: (res) => {
        if (res && res.data && res.stats) {
          this.topics = res.data.stats[0].genre
          this.topics.push('All')
        }
      }
    })
  }

  onPaginateNext() {
    if (this.allBlogs.length >= 6) {
      this.pageNumber = this.pageNumber + 1;
      this.onAllBlogs(this.pageNumber, this.filter)
    }
  }

  onPaginatePrev() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      this.onAllBlogs(this.pageNumber, this.filter)
    }
  }

  onAllBlogs(pageNumber, filter) {
    return this.blogService.getAllBlogs(pageNumber, filter).subscribe({
      next: (res) => {
        if (res && res.data && res.data.docs && res.data.docs.length) {
          this.allBlogs = res.data.docs;
        }
      }
    })
  }

  onFilterCategory(topic) {
    this.onAllBlogs(this.pageNumber, topic)
  }

  onFilterSubmit() {
    this.onAllBlogs(this.pageNumber, this.filterForm.value)
  }

  onSearch(term) {
    return this.blogService.searchBlog(term.target.value).subscribe({
      next: (res) => {
        if (res && res.data && res.data.search && res.data.search.length) {
          this.allBlogs = res.data.search
        } else {
          this.onAllBlogs(this.pageNumber, this.filter);
        }
      }
    })
  }
}
