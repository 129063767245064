<div class="navbar__container d-flex justify-content-between container-lg container-fluid">
  <div class="logo" [routerLink]="['/']">Dr.V.V.Rao</div>
  <div class="protected d-flex">
    <button [routerLink]="['signup']" *ngIf="!me" class="mr-5 auth btn">Sign up</button>
    <button [routerLink]="['login']" *ngIf="!me" class="auth btn">Login</button>
    <div *ngIf="me && me._id" (click)="onDropdown()">
      <div class="dropdown">
        <img [src]="me.photo" alt="user photo" class="navbar--user dropbtn">
        <div class="dropdown-content" *ngIf="me.role != 'admin'">
          <button [routerLink]="['profile', me._id]" class="auth-drop auth--dropdown" type="button">Profile</button>
          <button (click)="logout()" class=" auth-drop auth--dropdown" type="button">Logout</button>
        </div>
        <div class="dropdown-content" *ngIf="me.role === 'admin'">
          <button [routerLink]="['admin-profile', me._id]" class="auth-drop auth--dropdown" type="button">Profile</button>
          <!-- <button class="auth auth--dropdown" type="button">Bookmarks</button> -->
          <button (click)="logout()" class="auth-drop auth--dropdown" type="button">Logout</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div *ngIf="!me" class="d-flex justify-content-center align-items-center review--open">
  <div class="spinner-grow text-dark ml-2" role="status" *ngFor="let data of dummayArray">
    <span class="sr-only">Loading...</span>
  </div>
</div> -->
